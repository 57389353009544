import { useEffect, useState } from "react";

interface hookMember {
  data: any;
}

const useDalgoTMediaDashboard = (): hookMember => {
  const [data, setData] = useState<any>({});

  const fetchGPS = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/dalgot/list`, {
        headers: {
          key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
        },
      });
      const jsonData = await response.json();

      // console.log(jsonData);
      setData(jsonData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchGPS();
  }, []);

  return { data };
};

export default useDalgoTMediaDashboard;
