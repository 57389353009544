import useDalgoTDashboard from "./hooks/useDalgoTMediaDashboard";
import "./css/useDalgoTMediaDashboard.scss";

const DalgoTDashboard2: any = () => {
  const hook = useDalgoTDashboard();

  return (
    <div id="dalgoTdashboard2">
      <h1 id="title">달고 T - 매체 관제</h1>
      <table>
        <thead>
          <tr>
            <td>업데이트 펌웨어 버전</td>
            <td>업로드 일자</td>
            <td>업데이트 앱 버전</td>
            <td>업로드 일자</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{hook?.data?.update?.firmware[0]}</td>
            <td>{hook?.data?.update?.firmware[1].split("T")[0]}</td>
            <td>{hook?.data?.update?.app[0]}</td>
            <td>{hook?.data?.update?.app[1].split("T")[0]}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <td>기기번호</td>
            <td>이름</td>
            <td>차량번호</td>
            <td>mac 주소</td>
            <td>펌웨어 버전</td>
            <td>펌웨어 배포일자</td>
            <td>앱 버전</td>
            <td>앱 배포일자</td>
          </tr>
        </thead>
        <tbody>
          {hook.data?.media?.map((item: any, index: number) => {
            return (
              <tr key={index}>
                <td>{item.dalgot_id}</td>
                <td>{item.name ?? "-"}</td>
                <td>{item.car_number ?? "-"}</td>
                <td>{item.mac_address ?? "-"}</td>
                <td>{item.firmware_version}</td>
                <td>{item.firmware_date.split("T")[0]}</td>
                <td>{item.app_version}</td>
                <td>{item.app_date.split("T")[0]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DalgoTDashboard2;
