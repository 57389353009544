import { createBrowserRouter } from "react-router-dom";
import Main from "../screens/main";
import UpdateMedia from "../screens/mediaComponent/media/update";
import MainLayout from "../components/layouts/main/MainLayout";
import ListMedia from "../screens/mediaComponent/media/mediaList";
import SiteList from "../screens/siteComponent/site/siteList";
import UpdateSite from "../screens/siteComponent/site/update";
import MediaList from "../screens/mediaComponent/media/mediaList";
import CreateMedia from "../screens/mediaComponent/media/create";
import AIDeviceList from "../screens/aiComponent/aiDevice/aiDeviceList";
import AIDeviceUpdate from "../screens/aiComponent/aiDevice/aiDeviceUpdate";
import AIDeviceCreate from "../screens/aiComponent/aiDevice/aiDeviceCreate";
import AIDeviceTypeList from "../screens/aiComponent/aiDeviceType/aiDeviceTypeList";
import AIDeviceTypeUpdate from "../screens/aiComponent/aiDeviceType/aiDeviceTypeUpdate";
import AIDeviceTypeCreate from "../screens/aiComponent/aiDeviceType/aiDeviceTypeCreate";
import CameraList from "../screens/cameraComponent/camera/cameraList";
import CameraCreate from "../screens/cameraComponent/camera/cameraCreate";
import CameraUpdate from "../screens/cameraComponent/camera/cameraUpdate";
import CameraTypeList from "../screens/cameraComponent/cameraType/cameraTypeList";
import CameraTypeCreate from "../screens/cameraComponent/cameraType/cameraTypeCreate";
import CameraTypeUpdate from "../screens/cameraComponent/cameraType/cameraTypeUpdate";
import SiteFieldList from "../screens/siteComponent/siteField/siteFieldList";
import SiteFieldCreate from "../screens/siteComponent/siteField/siteFieldCreate";
import SiteFieldUpdate from "../screens/siteComponent/siteField/siteFieldUpdate";
import AIDeviceIssueList from "../screens/aiComponent/aiDeviceIssue/aiDeviceIssueList";
import AIDeviceIssueCreate from "../screens/aiComponent/aiDeviceIssue/aiDeviceIssueCreate";
import AIDeviceIssueUpdate from "../screens/aiComponent/aiDeviceIssue/aiDeviceIssueUpdate";
import SiteCreate from "../screens/siteComponent/site/siteCreate";
import DailyEffectList from "../screens/effectComponent/dailyEffect/dailyEffectList";
import DailyRealEffectList from "../screens/effectComponent/dailyRealEffect/dailyRealEffect";
import HourlyEffectList from "../screens/effectComponent/hourlyEffect/hourlyEffectList";
import HourlyRealEffectList from "../screens/effectComponent/hourlyRealEffect/hourlyRealEffectList";
import MediaEffectList from "../screens/effectComponent/mediaEffect/mediaEffectList";
import AdlyEffectList from "../screens/effectComponent/adlyEffect/adlyEffectList";
import DailyDashboard from "../screens/dashboardComponent/dailyDashboard";
import WeeklyDashboard from "../screens/dashboardComponent/weeklyDashboard";
import NetworkDeviceList from "../screens/networkComponent/networkDevice/networkDeviceList";
import NetworkDeviceUpdate from "../screens/networkComponent/networkDevice/networkDeviceUpdate";
import NetworkDeviceCreate from "../screens/networkComponent/networkDevice/networkDeviceCreate";
import NetworkDeviceTypeList from "../screens/networkComponent/networkDeviceType/networkDeviceTypeList";
import NetworkDeviceTypeCreate from "../screens/networkComponent/networkDeviceType/networkDeviceTypeCreate";
import NetworkDeviceTypeUpdate from "../screens/networkComponent/networkDeviceType/networkDeviceTypeUpdate";
import AdList from "../screens/adComponent/ad/adList";
import AdCorpFieldList from "../screens/adComponent/adCorpField/adCorpFieldList";
import AdCorpFieldCreate from "../screens/adComponent/adCorpField/adCorpFieldCreate";
import AdCorpFieldUpdate from "../screens/adComponent/adCorpField/adCorpFieldUpdate";
import AdFieldList from "../screens/adComponent/adField/adFieldList";
import AdFieldCreate from "../screens/adComponent/adField/adFieldCreate";
import AdFieldUpdate from "../screens/adComponent/adField/adFieldUpdate";
import AdVideoInfoList from "../screens/adComponent/adVideoInfo/adVideoInfoList";
import AdVideoInfoCreate from "../screens/adComponent/adVideoInfo/adVideoInfoCreate";
import AdVideoInfoUpdate from "../screens/adComponent/adVideoInfo/adVideoInfoUpdate";
import AdvertiserList from "../screens/adComponent/advertiser/advertiserList";
import AdvertiserCreate from "../screens/adComponent/advertiser/advertiserCreate";
import AdvertiserUpdate from "../screens/adComponent/advertiser/advertiserUpdate";
import AiDeviceCompanyList from "../screens/aiComponent/aiDeviceCompany/aiDeviceCompanyList";
import AiDeviceCompanyCreate from "../screens/aiComponent/aiDeviceCompany/aiDeviceCompanyCreate";
import AiDeviceCompanyUpdate from "../screens/aiComponent/aiDeviceCompany/aiDeviceCompanyUpdate";
import AiModuleList from "../screens/aiComponent/aiModule/aiModuleList";
import AiModuleCreate from "../screens/aiComponent/aiModule/aiModuleCreate";
import AiModuleUpdate from "../screens/aiComponent/aiModule/aiModuleUpdate";
import BusinessTypeList from "../screens/businessType/businessTypeList";
import BusinessTypeCreate from "../screens/businessType/businessTypeCreate";
import BusinessTypeUpdate from "../screens/businessType/businessTypeUpdate";
import CpvList from "../screens/cpv/cpvList";
import CpvCreate from "../screens/cpv/cpvCreate";
import CpvUpdate from "../screens/cpv/cpvUpdate";
import MediaDeviceList from "../screens/mediaComponent/mediaDevice/mediaDeviceList";
import MediaDeviceCreate from "../screens/mediaComponent/mediaDevice/mediaDeviceCreate";
import MediaDeviceUpdate from "../screens/mediaComponent/mediaDevice/mediaDeviceUpdate";
import MediaNetworkList from "../screens/mediaComponent/mediaNetwork/mediaNetworkList";
import MediaNetworkCreate from "../screens/mediaComponent/mediaNetwork/mediaNetworkCreate";
import MediaNetworkUpdate from "../screens/mediaComponent/mediaNetwork/mediaNetworkUpdate";
import LoginPage from "../components/layouts/login/LoginPage";
import AdDashboard from "../screens/dashboardComponent/adDashboard";
import AdUpdate from "../screens/adComponent/ad/adUpdate";
import ScheduleDashboard from "../screens/dashboardComponent/scheduleDashboard";
import ScheduleDetailDashboard from "../screens/dashboardComponent/scheduleDetailDashboard";
import InstallReportList from "../screens/installReport/installReportList";
import ActualImageCreate from "../screens/installReport/actualImageCreate";
import ActualImageUpdate from "../screens/installReport/actualImageUpdate";
import InstallCreate from "../screens/installReport/installCreate";
import InstallUpdate from "../screens/installReport/installUpdate";
import InspectionCreate from "../screens/installReport/inspectionCreate";
import InspectionUpdate from "../screens/installReport/inspectionUpdate";
import DemolishCreate from "../screens/installReport/demolishCreate";
import DemolishUpdate from "../screens/installReport/demolishUpdate";
import MediaPointList from "../screens/mediaComponent/mediaPoint/mediaPointList";
import MediaPointCreate from "../screens/mediaComponent/mediaPoint/mediaPointCreate";
import MediaPointUpdate from "../screens/mediaComponent/mediaPoint/mediaPointUpdate";
import VersionManager from "../screens/versionManager/versionFolder/versionManager";
import MediaVersionHistory from "../screens/versionManager/mediaVersionHistory/mediaVersionHistory";
import MediaHistoryDetil from "../screens/versionManager/mediaVersionHistory/[auth_number]/mediaVersionHistoryDetail";
import LogInquiryList from "../screens/inquiryComponent/logInquiry/logInquiryList";
import CsvInquiryList from "../screens/inquiryComponent/csvInquiry/csvInquiryList";
import DalgoTDashboard from "../screens/dashboardComponent/dalgoT/dalgoTDailyDashboard";
import DalgoTPath from "../screens/dashboardComponent/dalgoT/dalgoTPath";
import DailyRealEffectGraphsList from "../screens/graphComponent/dailyRealEffectGraphs/dailyRealEffectGraphsList";
import DalgoTMonthlyDashboard from "../screens/dashboardComponent/dalgoT/dalgoTMonthlyDashboard";
import Demo from "../screens/demo/Demo";
import RegistrationList from "../screens/adddiLightComponent/registration/list";
import DalgoTDashboard2 from "../screens/dashboardComponent/dalgoT/dalgoTMediaDashboard";

interface hookMember {
  router: any;
}

const useAppRoute = (): hookMember => {
  const routes = {
    "/adComponent/ad": [
      { path: "/adList", element: <AdList /> },
      {
        path: "/adUpdate/:adId",
        element: <AdUpdate />,
      },
    ],
    "/adComponent/adCorpField": [
      { path: "/adCorpFieldList", element: <AdCorpFieldList /> },
      { path: "/adCorpFieldCreate", element: <AdCorpFieldCreate /> },
      {
        path: "/adCorpFieldUpdate/:adCorpFieldId",
        element: <AdCorpFieldUpdate />,
      },
    ],
    "/adComponent/adField": [
      { path: "/adFieldList", element: <AdFieldList /> },
      { path: "/adFieldCreate", element: <AdFieldCreate /> },
      { path: "/adFieldUpdate/:adFieldId", element: <AdFieldUpdate /> },
    ],
    "/adComponent/adVideoInfo": [
      { path: "/adVideoInfoList", element: <AdVideoInfoList /> },
      { path: "/adVideoInfoCreate", element: <AdVideoInfoCreate /> },
      {
        path: "/adVideoInfoUpdate/:adVideoInfoId",
        element: <AdVideoInfoUpdate />,
      },
    ],
    "/adComponent/advertiser": [
      { path: "/advertiserList", element: <AdvertiserList /> },
      { path: "/advertiserCreate", element: <AdvertiserCreate /> },
      {
        path: "/advertiserUpdate/:advertiserId",
        element: <AdvertiserUpdate />,
      },
    ],
    "/mediaComponent/media": [
      { path: "/update/:mediaId", element: <UpdateMedia /> },
      { path: "/list", element: <MediaList /> },
      { path: "/create", element: <CreateMedia /> },
    ],
    "/mediaComponent/mediaDevice": [
      { path: "/mediaDeviceList", element: <MediaDeviceList /> },
      { path: "/mediaDeviceCreate", element: <MediaDeviceCreate /> },
      {
        path: "/mediaDeviceUpdate/:mediaDeviceId",
        element: <MediaDeviceUpdate />,
      },
    ],
    "/mediaComponent/mediaNetwork": [
      { path: "/mediaNetworkList", element: <MediaNetworkList /> },
      { path: "/mediaNetworkCreate", element: <MediaNetworkCreate /> },
      {
        path: "/mediaNetworkUpdate/:mediaNetworkId",
        element: <MediaNetworkUpdate />,
      },
    ],
    "/mediaComponent/mediaPoint": [
      { path: "/list", element: <MediaPointList /> },
      { path: "/create", element: <MediaPointCreate /> },
      { path: "/update/:mediaId", element: <MediaPointUpdate /> },
    ],
    "/siteComponent/site": [
      { path: "/siteCreate", element: <SiteCreate /> },
      { path: "/list", element: <SiteList /> },
      { path: "/update/:siteId", element: <UpdateSite /> },
    ],
    "/aiComponent/aiDevice": [
      { path: "/aiDeviceList", element: <AIDeviceList /> },
      { path: "/aiDeviceUpdate/:aiDeviceId", element: <AIDeviceUpdate /> },
      { path: "/aiDeviceCreate", element: <AIDeviceCreate /> },
    ],
    "/aiComponent/aiDeviceType": [
      { path: "/aiDeviceTypeList", element: <AIDeviceTypeList /> },
      { path: "/aiDeviceTypeCreate", element: <AIDeviceTypeCreate /> },
      {
        path: "/aiDeviceTypeUpdate/:aiDeviceTypeId",
        element: <AIDeviceTypeUpdate />,
      },
    ],
    "/aiComponent/aiDeviceCompany": [
      { path: "/aiDeviceCompanyList", element: <AiDeviceCompanyList /> },
      { path: "/aiDeviceCompanyCreate", element: <AiDeviceCompanyCreate /> },
      {
        path: "/aiDeviceCompanyUpdate/:aiDeviceCompanyId",
        element: <AiDeviceCompanyUpdate />,
      },
    ],
    "/aiComponent/aiModule": [
      { path: "/aiModuleList", element: <AiModuleList /> },
      { path: "/aiModuleCreate", element: <AiModuleCreate /> },
      { path: "/aiModuleUpdate/:aiModuleId", element: <AiModuleUpdate /> },
    ],
    "/businessType": [
      { path: "/businessTypeList", element: <BusinessTypeList /> },
      { path: "/businessTypeCreate", element: <BusinessTypeCreate /> },
      {
        path: "/businessTypeUpdate/:businessTypeId",
        element: <BusinessTypeUpdate />,
      },
    ],
    "/cpv": [
      { path: "/cpvList", element: <CpvList /> },
      { path: "/cpvCreate", element: <CpvCreate /> },
      { path: "/cpvUpdate/:cpvId", element: <CpvUpdate /> },
    ],
    "/cameraComponent/camera": [
      { path: "/cameraList", element: <CameraList /> },
      { path: "/cameraCreate", element: <CameraCreate /> },
      { path: "/cameraUpdate/:cameraId", element: <CameraUpdate /> },
    ],
    "/cameraComponent/cameraType": [
      { path: "/cameraTypeList", element: <CameraTypeList /> },
      { path: "/cameraTypeCreate", element: <CameraTypeCreate /> },
      {
        path: "/cameraTypeUpdate/:cameraTypeId",
        element: <CameraTypeUpdate />,
      },
    ],
    "/siteComponent/siteField": [
      { path: "/siteFieldList", element: <SiteFieldList /> },
      { path: "/siteFieldCreate", element: <SiteFieldCreate /> },
      { path: "/siteFieldUpdate/:siteFieldId", element: <SiteFieldUpdate /> },
    ],
    "/aiComponent/aiDeviceIssue": [
      { path: "/aiDeviceIssueList", element: <AIDeviceIssueList /> },
      { path: "/aiDeviceIssueCreate", element: <AIDeviceIssueCreate /> },
      {
        path: "/aiDeviceIssueUpdate/:aiDeviceIssueId",
        element: <AIDeviceIssueUpdate />,
      },
    ],
    "/effectComponent": [
      { path: "/dailyEffect/dailyEffectList", element: <DailyEffectList /> },
      {
        path: "/dailyRealEffect/dailyRealEffectList",
        element: <DailyRealEffectList />,
      },
      { path: "/hourlyEffect/hourlyEffectList", element: <HourlyEffectList /> },
      {
        path: "/hourlyRealEffect/hourlyRealEffectList",
        element: <HourlyRealEffectList />,
      },
      { path: "/mediaEffect/mediaEffectList", element: <MediaEffectList /> },
      { path: "/adlyEffect/adlyEffectList", element: <AdlyEffectList /> },
    ],
    "/graphComponent/dailyRealEffectGraphs": [
      {
        path: "/dailyRealEffectGraphsList",
        element: <DailyRealEffectGraphsList />,
      },
    ],

    "/networkComponent/networkDevice": [
      { path: "/networkDeviceList", element: <NetworkDeviceList /> },
      {
        path: "/networkDeviceUpdate/:networkDeviceId",
        element: <NetworkDeviceUpdate />,
      },
      { path: "/networkDeviceCreate", element: <NetworkDeviceCreate /> },
    ],
    "/networkComponent/networkDeviceType": [
      { path: "/networkDeviceTypeList", element: <NetworkDeviceTypeList /> },
      {
        path: "/networkDeviceTypeCreate",
        element: <NetworkDeviceTypeCreate />,
      },
      {
        path: "/networkDeviceTypeUpdate/:networkDeviceTypeId",
        element: <NetworkDeviceTypeUpdate />,
      },
    ],
    "/dashboard": [
      { path: "/daily", element: <DailyDashboard /> },
      { path: "/weekly", element: <WeeklyDashboard /> },
      { path: "/ad", element: <AdDashboard /> },
      { path: "/schedule", element: <ScheduleDashboard /> },
      {
        path: "/schedule/:mediaId",
        element: <ScheduleDetailDashboard />,
      },
    ],
    "/dalgoT": [
      { path: "/monthly", element: <DalgoTMonthlyDashboard /> },
      { path: "/daily", element: <DalgoTDashboard /> },
      { path: "/path", element: <DalgoTPath /> },
      { path: "/media", element: <DalgoTDashboard2 /> },
    ],
    "/login": [{ path: "/", element: <LoginPage /> }],
    "/installReport": [
      { path: "/installReportList", element: <InstallReportList /> },
      { path: "/actualImageCreate", element: <ActualImageCreate /> },
      {
        path: "/actualImageUpdate/:installReportId",
        element: <ActualImageUpdate />,
      },
      { path: "/installCreate", element: <InstallCreate /> },
      { path: "/installUpdate/:installReportId", element: <InstallUpdate /> },
      { path: "/inspectionCreate", element: <InspectionCreate /> },
      {
        path: "/inspectionUpdate/:installReportId",
        element: <InspectionUpdate />,
      },
      { path: "/demolishCreate", element: <DemolishCreate /> },
      { path: "/demolishUpdate/:installReportId", element: <DemolishUpdate /> },
    ],
    "/versionManager": [
      { path: "/folder", element: <VersionManager></VersionManager> },
      {
        path: "/history",
        element: <MediaVersionHistory></MediaVersionHistory>,
      },
      {
        path: "/history/:mediaId", // 동적 라우팅을 추가합니다.
        element: <MediaHistoryDetil />, // 상세 페이지 컴포넌트를 여기에 설정합니다.
      },
    ],
    inquiry: [
      { path: "/logInquiryList", element: <LogInquiryList /> },
      { path: "/csvInquiryList", element: <CsvInquiryList /> },
    ],
    demo: [{ path: "/", element: <Demo /> }],
    "/adddiLight": [
      { path: "/registrationList", element: <RegistrationList /> },
    ],
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: Object.entries(routes).flatMap(([basePath, routes]) =>
        routes.map(({ path, element }) => ({
          path: basePath + path,
          element,
        }))
      ),
    },
  ]);

  return { router };
};

export default useAppRoute;
